import React from "react";
import * as styles from "./SearchInput.module.css";

interface Props {
  inputProps?: any;
  placeHolder: string;
  color?: "green" | "white";
  borderColor?: "green" | "white";
  disableSubmit: boolean;
}

export const SearchInput: React.FC<Props> = ({
  inputProps,
  placeHolder,
  color = "green",
  borderColor = "green",
  disableSubmit,
}) => {
  let colorClass = color === "white" ? styles.colorWhite : "";
  let borderColorClass = borderColor === "white" ? styles.borderWhite : "";

  return (
    <div
      className={`${styles.searchWrapper} ${colorClass} ${borderColorClass}`}
    >
      <input type="search" placeholder={placeHolder} {...inputProps} />
      <button
        type="submit"
        className={disableSubmit ? styles.disabled : undefined}
        aria-label="Submit"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.655 15.941">
          <path
            d="m16.105 15.396-5.493-5.54m.777-3.868A5.307 5.213 0 0 1 6.082 11.2 5.307 5.213 0 0 1 .775 5.988 5.307 5.213 0 0 1 6.082.775a5.307 5.213 0 0 1 5.307 5.213Z"
            fill="none"
            stroke="currentColor"
            strokeWidth="1.55"
          />
        </svg>
      </button>
    </div>
  );
};
